import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Banner, BlockStack, Button, InlineStack, Spinner } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import CommonForm from "@/Components/Common/CommonForm";
import { useStopRecording } from "@/Context/StopRecordingContext";
import { ToastContext } from "@/Context/ToastContext";
import { logger } from "@/Services/Logger/Index";
import { uploadFileToServer } from "@/Utils/Utils";
import { getFields } from "../../../../Assets/Mocks/StageDetails.mock";

export default function InterviewAnswer(props) {
  const { currentQuestion, handleNextQuestion, assessmentData, setIsSubmitted, isPublic } = props;
  const fetch = useAuthenticatedFetch();
  const [value, setValue] = useState(getFields()?.["initialValues"]);
  const [valueFields, setValueFields] = useState(getFields()?.["fields"]);
  const { handleStopRecording, stopRecordingContext } = useStopRecording();
  const formRef = useRef();
  const { showToast } = useContext(ToastContext);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [AnswerProgress, setAnswerProgress] = useState(0);
  const [countdown, setCountdown] = useState(2);
  const [timeLeft, setTimeLeft] = useState();
  const [thinkCountDown, setThinkCountDown] = useState();
  const [timeLefThinking, setTimeLeftThinking] = useState(0);
  const [anwserCountDown, setAnwserCountDown] = useState(false);
  const [showCountDown, setshowCountDown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = screen.width <= 768; // Example threshold for mobile

  useEffect(() => {
    setLoading(true);
    if (currentQuestion?.crtQuation?.answerType === "video" || !currentQuestion?.crtQuation?.answerType) {
      handleStopRecording(true);
      setshowCountDown(false);
    } else {
      handleStopRecording(false);
      setshowCountDown(true);
    }
    if (currentQuestion?.crtQuation) {
      const fields = getFields(currentQuestion?.crtQuation)?.["fields"];
      setValueFields(fields);
      const initialValues = getFields(currentQuestion?.crtQuation)?.["initialValues"];
      setValue(initialValues);
      const minuts = Number(currentQuestion?.crtQuation?.answerTime) * 60 * 1000;
      const timeLeftInSeconds = Math.ceil(minuts / 1000);
      setCountdown(
        Number(
          currentQuestion?.crtQuation?.answerTime > 15
            ? currentQuestion?.crtQuation?.answerTime
            : timeLeftInSeconds
        )
      );
      const minutes = minTwoDigits(Math.floor((timeLeftInSeconds % (60 * 60)) / 60));
      const seconds = minTwoDigits(Math.floor(timeLeftInSeconds % 60));
      setTimeLeft(`${minutes}:${seconds}`);
      if (currentQuestion?.crtQuation?.answerType !== "video" || !currentQuestion?.crtQuation?.aiGenerated) {
        const second = Number(currentQuestion?.crtQuation?.questionTime) * 1000;
        const timeLefts = Math.ceil(second / 1000);
        setThinkCountDown(Number(timeLefts));
        const thinkingseconds = minTwoDigits(Math.floor(timeLefts % 60));
        setTimeLeftThinking(currentQuestion?.crtQuation?.questionTime === 0 ? 0 : `${thinkingseconds}`);
        setAnwserCountDown(currentQuestion?.crtQuation?.questionTime === 0 ? true : false);
      }
    }
    setLoading(false);
  }, [currentQuestion]);

  useEffect(() => {
    const handleCopy = (e) => {
      e.preventDefault();
    };

    const handlePaste = (e) => {
      e.preventDefault();
    };

    document.addEventListener("copy", handleCopy);
    document.addEventListener("paste", handlePaste);

    return () => {
      document.removeEventListener("copy", handleCopy);
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  useEffect(() => {
    if (count == 0) {
      setCount(assessmentData?.tabChangeCount ? assessmentData?.tabChangeCount : 0);
    }
    const handleVisibilityChange = async (event) => {
      if (document.visibilityState === "hidden") {
        setCount(count + 1);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [count, assessmentData]);

  function minTwoDigits(n) {
    return String(n).padStart(2, "0");
  }

  useEffect(() => {
    let interval;

    if (countdown > -1 && anwserCountDown && !stopRecordingContext && currentQuestion?.crtQuation?.answerTime) {
      interval = setInterval(() => {
        if (countdown > 0) {
          const minutes = minTwoDigits(Math.floor((countdown % (60 * 60)) / 60));
          const seconds = minTwoDigits(Math.floor(countdown % 60));
          setTimeLeft(`${minutes}:${seconds}`);
          setCountdown(countdown - 1);
        } else {
          handleNext();
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countdown, stopRecordingContext, anwserCountDown]);

  useEffect(() => {
    let interval;

    if (
      thinkCountDown > -1 &&
      currentQuestion?.crtQuation?.questionTime &&
      !stopRecordingContext &&
      showCountDown
    ) {
      interval = setInterval(() => {
        if (thinkCountDown > 0) {
          const seconds = minTwoDigits(Math.floor(thinkCountDown % 60));
          setTimeLeftThinking(`${seconds}`);
          setThinkCountDown(thinkCountDown - 1);
        } else {
          setAnwserCountDown(true);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [thinkCountDown]);

  useEffect(() => {
    if (
      !stopRecordingContext &&
      (currentQuestion?.crtQuation?.answerType === "video" || currentQuestion?.crtQuation?.aiGenerated)
    ) {
      setTimeLeftThinking(0);
      setAnwserCountDown(true);
      setshowCountDown(false);
    }
  }, [stopRecordingContext]);

  useEffect(() => {
    if (AnswerProgress == 0) {
      setAnswerProgress(assessmentData?.answers?.length ? assessmentData?.answers?.length : 0);
    }
    if (AnswerProgress >= assessmentData?.questionsList?.length) {
      setIsLoading(false);
      setIsSubmitted(true);
    }
  }, [AnswerProgress, assessmentData]);

  const uploadFile = async (data) => {
    let lastQuation = currentQuestion?.currentQuationIndex == assessmentData?.questionsList?.length - 1;
    if (lastQuation) {
      showToast("We are still uploading your videos please don’t close the page.");
    }
    const uploadedVideo = await uploadFileToServer(fetch, data);
    if (uploadedVideo) {
      return { uploadedVideo: uploadedVideo };
    }
  };

  const assessmentAnswer = async (answer, quation) => {
    let lastQuation =
      quation?.crtQuation?.question ===
      assessmentData?.questionsList[assessmentData?.questionsList.length - 1].question;
    // let lastQuation = quation?.currentQuationIndex == assessmentData?.questionsList?.length - 1;
    if (lastQuation && !answer?.answerVideoURL) {
      showToast("We are still uploading your videos please don’t close the page.");
    }
    let requestData = {
      isSubmit: lastQuation,
      answers: answer,
      assessment_id: assessmentData?._id,
      currentQuestionIndex: quation?.currentQuationIndex,
      tabChangeCount: count,
      isPublic: isPublic,
    };

    let res = await fetch.post("/assessment", requestData, lastQuation);
    if (res?.data) {
      setAnswerProgress(res?.data?.answers?.length);
    }
  };

  const convertText = async (answer) => {
    let stringAns = answer.join(", ");
    return stringAns;
  };

  const handleNext = async () => {
    if (formRef.current) {
      handleStopRecording(true);
      setTimeout(async () => {
        formRef.current.handleSubmit();
      }, 1000);
    }
  };

  const handleSubmit = useCallback(
    async (values) => {
      try {
        let answer = {
          question: currentQuestion?.crtQuation?.questionVideo?.assetUrl
            ? currentQuestion?.crtQuation?.questionVideo?.assetUrl
            : currentQuestion?.crtQuation?.question,
          aiEvaluation: currentQuestion?.crtQuation?.aiEvaluation || currentQuestion?.crtQuation?.aiGenerated,
          currentQuestionIndex: currentQuestion?.currentQuationIndex,
          isProcessing: true,
        };
        formRef.current.resetForm();
        await handleNextQuestion();
        let lastQuation =
          currentQuestion?.crtQuation?.question ===
          assessmentData?.questionsList[assessmentData?.questionsList.length - 1].question;
        if (lastQuation) {
          setIsLoading(true);
        }
        if (values?.answerVideo?.recordedBlob) {
          let lastQuation = currentQuestion?.currentQuationIndex == assessmentData?.questionsList?.length - 1;
          const data = {
            file: values.answerVideo.recordedBlob,
            fileName: `video-${assessmentData?._id}-${Date.now()}.webm`,
            isPublic: true,
            convertToVideo: true,
            lastQuation,
            assessment_id: assessmentData?._id,
            answer: answer,
          };
          let fileData = await uploadFile(data);
          if(fileData?.uploadedVideo?.assetUrl){
          answer.answerVideoText = "";
          answer.answerVideoURL = fileData?.uploadedVideo?.assetUrl || null;
          answer.videoURL = values?.answerVideo?.videoURL;
          answer.duration = values?.answerVideo?.duration;
          await assessmentAnswer(answer, currentQuestion);
          }
        }
        if (values.textAns) {
          answer.answerVideoText =
            typeof values.textAns === "object" ? await convertText(values.textAns) : values.textAns;
          await assessmentAnswer(answer, currentQuestion);
        }
      } catch (err) {
        logger.error(err);
      }
    },
    [currentQuestion, count]
  );
  if (loading) {
    return <></>;
  }
  if (isLoading) {
    return (
      <div className="loading">
        <div className="loading-spinner">
          <Spinner></Spinner>
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <BlockStack gap="200">
        <BlockStack inlineAlign="center" gap="200">
          <div className={`${currentQuestion?.crtQuation?.answerType == "text" ? "text-input" : ""}`}>
            <BlockStack gap="200">
              {thinkCountDown > 0 && currentQuestion?.crtQuation?.questionTime && showCountDown ? (
                <Banner
                  tone="info"
                  title={`Get ready with your answer, as your Answer Time begin in ${timeLefThinking} seconds.`}
                />
              ) : (
                <></>
              )}
              {countdown > 0 &&
              anwserCountDown &&
              (currentQuestion?.crtQuation?.answerType === "video" || currentQuestion?.crtQuation?.aiGenerated
                ? !stopRecordingContext
                : !stopRecordingContext) &&
              !loading &&
              currentQuestion?.crtQuation?.answerTime ? (
                <Banner
                  tone="info"
                  title={
                    isMobile
                      ? `Complete your answer within ${timeLeft} minutes.`
                      : `Complete your answer within ${timeLeft} minutes; you'll be redirected to the next question when time runs out.`
                  }
                />
              ) : (
                <></>
              )}
              {/* <InlineStack align="center"> */}
              {currentQuestion?.crtQuation?.answerType === "video" || currentQuestion?.crtQuation?.aiGenerated ? (
                <InlineStack align="center" gap={200}>
                  <CommonForm
                    formFields={valueFields}
                    isSave={false}
                    formRef={formRef}
                    initialValues={value}
                    noValueChanged={false}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                  />
                </InlineStack>
              ) : (
                <CommonForm
                  formFields={valueFields}
                  isSave={false}
                  formRef={formRef}
                  initialValues={value}
                  noValueChanged={false}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                />
              )}
            </BlockStack>
          </div>
        </BlockStack>
        {(currentQuestion?.crtQuation?.answerType == "video" || !currentQuestion?.crtQuation?.answerType) &&
        stopRecordingContext ? (
          <></>
        ) : (
          <div
            className={`${
              currentQuestion?.crtQuation?.questionType == "video" &&
              currentQuestion?.crtQuation?.answerType != "number"
                ? "video-button"
                : "other-button"
            }
            ${
              currentQuestion?.crtQuation?.questionType == "video" &&
              (currentQuestion?.crtQuation?.answerType == "text" ||
                currentQuestion?.crtQuation?.answerType == "checkbox" ||
                currentQuestion?.crtQuation?.answerType == "radio")
                ? "text-button"
                : "other-quation"
            }`}
          >
            <Button onClick={handleNext} variant="primary" size="micro">
              {currentQuestion?.currentQuationIndex >= assessmentData?.questionsList?.length - 1
                ? "Submit"
                : "Go to Next Question"}
            </Button>
          </div>
        )}
      </BlockStack>
    </div>
  );
}
