import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, EmptyState, InlineStack, LegacyCard, Modal } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import * as Images from "@/Assets/Index";
import CommonForm from "@/Components/Common/CommonForm";
import { ToastContext } from "@/Context/ToastContext";
import { sendAssessmentFields, sendAssessmentInitialValues } from "../../../Assets/Mocks/Candidate.mock";
import { sourcingFormFields, sourcingInitialValues } from "../../../Assets/Mocks/ResourcingMock";
import { getSessionStorageItem, setSessionStorageItem } from "../../../Utils/Index";
import { CandidateDetails } from "../Jobs/CandidateDetails.jsx";

export function Resourcing() {
  const childRef = useRef();
  const key = "sourcingList";
  const [isResourcing, setIsResourcing] = useState(false);
  const formRef = useRef();
  const [sendAssessment, setsendAssessment] = useState(false);
  const [isSendAssessment, setIsSendAssessment] = useState(false);
  const [candiateData, setcandiateData] = useState();
  const fetch = useAuthenticatedFetch();
  const { showToast } = useContext(ToastContext);
  const [searchValue, setsearchValue] = useState();

  const handleButtonClick = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  useEffect(() => {
    if (sendAssessment) {
      setIsSendAssessment(true);
    }
  }, [sendAssessment]);

  const handleSubmit = useCallback(
    async (e) => {
      if (candiateData?._id && sendAssessment) {
        await fetch.post("/stage/sendAssessment", {
          jobId: candiateData?.jobId,
          candidateId: candiateData?._id,
          stageId: candiateData.currentStage?._id,
          sendAssessmentType: e,
        });
        setsendAssessment(false);
        setIsSendAssessment(false);
        childRef?.current?.fetchData();
        showToast("Assessment sent successfully");
      } else {
        setsearchValue(e);
        setLocalStorageFilter(true, e, childRef);
        setIsResourcing(true);
      }
    },
    [isResourcing, candiateData, sendAssessment, searchValue]
  );

  const setLocalStorageFilter = (removeAllFilter, value, childRef) => {
    if (key) {
      const item = getSessionStorageItem(key);
      let { pagination, filter } = item || {};

      filter = [];

      const transformedFilter = Object.entries(value)
        .filter(([_, v]) => v !== "") // Exclude empty string values
        .map(([k, v]) => ({
          key: k,
          value: v,
          tabQuery: true,
        }));

      filter?.push(...transformedFilter); // Push the transformed values

      filter = !removeAllFilter ? filter?.filter((e) => e?.tabQuery == true) : filter;
      pagination = removeAllFilter ? { page: 1, pageSize: 10 } : pagination;
      setSessionStorageItem(key, { pagination, filter });
      childRef?.current && childRef?.current?.fetchData(pagination, filter);
    }
  };

  const handleSendAssessmentCanceled = useCallback(() => {
    setIsSendAssessment(false);
    setsendAssessment(false);
  }, []);

  const handleSenAssessmentConfirmed = useCallback(async () => {
    if (formRef.current && sendAssessment) {
      formRef.current.handleSubmit();
    }
  }, [candiateData, sendAssessment]);

  return (
    <>
      <CommonForm
        onSubmit={handleSubmit}
        formRef={formRef}
        initialValues={sourcingInitialValues}
        formFields={sourcingFormFields}
        isSave={false}
        noValueChanged={false}
      />
      <InlineStack align="end">
        <Button variant="primary" onClick={handleButtonClick}>
          Search
        </Button>
      </InlineStack>
      {isResourcing ? (
        <CandidateDetails sourcing={true} searchValue={searchValue} childRef={childRef}></CandidateDetails>
      ) : (
        <LegacyCard>
          <EmptyState heading="No Candidate list Found" image={Images.EmptyReuslt}></EmptyState>
        </LegacyCard>
      )}
      <Modal
        open={isSendAssessment}
        onClose={handleSendAssessmentCanceled}
        title="Send Assessment Method"
        primaryAction={{
          content: "Send",
          onAction: handleSenAssessmentConfirmed,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleSendAssessmentCanceled,
          },
        ]}
      >
        <Modal.Section>
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={sendAssessmentInitialValues}
            formFields={sendAssessmentFields}
            isSave={false}
          />
        </Modal.Section>
      </Modal>
    </>
  );
}
