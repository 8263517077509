export const totalExperience = [
  { value: "0", label: "Fresher" },
  { value: "1", label: 1 },
  { value: "2", label: 2 },
  { value: "3", label: 3 },
  { value: "4", label: 4 },
  { value: "5", label: 5 },
  { value: "6", label: 6 },
  { value: "7", label: 7 },
  { value: "8", label: 8 },
  { value: "9", label: 9 },
  { value: "10", label: 10 },
  { value: "11", label: 11 },
  { value: "12", label: 12 },
  { value: "13", label: 13 },
  { value: "14", label: 14 },
  { value: "15", label: 15 },
  { value: "16", label: "15+" },
];

export const getCandidateDetailFields = (jobData, publicUrlField) => {
  let fields = [
    {
      nested: "group",
      groupSize: publicUrlField?.fullName ? 1 : 2,
      section: false,
      subfields: [
        {
          id: "fullName",
          name: "fullName",
          label: "Full Name",
          validated: true,
          type: "text",
          placeholder: "Enter your full name",
          errormsg: "Full Name is required",
          dependOn: {
            name: "isPublicUrl",
            value: publicUrlField?.fullName === false ? publicUrlField?.fullName : true,
            type: "hidden",
          },
        },
        {
          id: "email",
          name: "email",
          label: "Email Address",
          validated: true,
          type: "email",
          placeholder: "Enter your email address",
          errormsg: "Email is required",
          dependOn: {
            name: "isPublicUrl",
            value: publicUrlField?.email === false ? publicUrlField?.email : true,
            type: "hidden",
          },
        },
        {
          id: "mobile",
          name: "mobile",
          label: "Mobile",
          type: "tel",
          autoComplete: "off",
          validated: publicUrlField?.fullName === true ? true : false,
          placeholder: "Enter your mobile number",
          errormsg: "Mobile Number is required",
          dependOn: {
            name: "isPublicUrl",
            value: publicUrlField?.mobile === false ? publicUrlField?.mobile : true,
            type: "hidden",
          },
        },
        {
          id: "platform",
          name: "platform",
          label: "Platform",
          validated: false,
          type: "text",
          placeholder: "Please specify the platform where candidate found us",
          errormsg: "Platform is required",
          dependOn: {
            name: "isPublicUrl",
            value: publicUrlField?.platform === false ? publicUrlField?.platform : true,
            type: "hidden",
          },
        },
        {
          id: "resume",
          name: "resume",
          label: "Resume",
          type: "fileUpload",
          validated: true,
          allowMultiple: false,
          size: "small",
          min: publicUrlField?.resumeRequered === true ? 1 : 0,
          errormsg: "Resume is required",
          requiredIndicator: publicUrlField?.resumeRequered === true ? true : false,
          fileType: "file",
          accept: ".pdf, .doc, .docx",
          dependOn: {
            name: "isPublicUrl",
            value: publicUrlField?.resume === false ? publicUrlField?.resume : true,
            type: "hidden",
          },
        },
        {
          id: "preferWorkMode",
          name: "preferWorkMode",
          label: "Prefer Work Mode",
          type: "select",
          validated: publicUrlField?.preferWorkModeRequered === true ? true : false,
          errormsg: "Prefer Work Mode is required",
          placeholder: "Select Your Work Mode",
          options: [
            { value: "In office", label: "Office" },
            { value: "Remote", label: "Remote" },
            { value: "Hybrid", label: "Hybrid" },
            { value: "Flexible", label: "Flexible" },
          ],
          helpText: jobData?.workMode ? `Job Preferred work mode is ${jobData?.workMode}` : "",
          dependOn: {
            name: "isPublicUrl",
            value: publicUrlField?.preferWorkMode === false ? publicUrlField?.preferWorkMode : true,
            type: "hidden",
          },
        },
        {
          id: "skills",
          name: "skills",
          label: "Skills",
          validated: publicUrlField
            ? publicUrlField.skillsRequered !== undefined
              ? publicUrlField.skillsRequered
              : false
            : true,
          type: "text",
          placeholder: "Enter your Skills",
          errormsg: "Skills is required",
          helpText: `Enter multiple Skills (Example : React JS, Angular, Node JS)`,
          dependOn: {
            name: "isPublicUrl",
            value: publicUrlField ? (publicUrlField.skills !== undefined ? publicUrlField.skills : false) : true,
            type: "hidden",
          },
        },
        {
          id: "currentCTC",
          name: "currentCTC",
          label: "Current CTC",
          type: "number",
          validated: publicUrlField?.currentCTCRequered === true ? true : false,
          errormsg: "Current CTC is required",
          helpText: "In LPA format (Example : 200000)",

          dependOn: {
            name: "isPublicUrl",
            value: publicUrlField?.currentCTC === false ? publicUrlField?.currentCTC : true,
            type: "hidden",
          },
        },
        {
          id: "expectedCTC",
          name: "expectedCTC",
          label: "Expected CTC",
          type: "number",
          helpText: "In LPA format (Example : 500000)",
          validated: publicUrlField?.expectedCTCRequered === true ? true : false,
          errormsg: "Expected CTC is required",
          dependOn: {
            name: "isPublicUrl",
            value: publicUrlField?.expectedCTC === false ? publicUrlField?.expectedCTC : true,
            type: "hidden",
          },
        },
        {
          id: "noticePeriod",
          name: "noticePeriod",
          label: "Notice Period (In days)",
          type: "select",
          validated: publicUrlField?.noticePeriodRequered === true ? true : false,
          errormsg: "Notice Period is required",
          placeholder: "Select your notice period",
          options: [
            { value: "Currently serving", label: "Currently serving" },
            { value: "Immediate joiner", label: "Immediate joiner" },
            { value: "15", label: "15" },
            { value: "30", label: "30" },
            { value: "60", label: "60" },
            { value: "90", label: "90" },
            { value: "90+", label: "90+" },
          ],
          dependOn: {
            name: "isPublicUrl",
            value: publicUrlField?.noticePeriod === false ? publicUrlField?.noticePeriod : true,
            type: "hidden",
          },
        },
        {
          id: "currentLocation",
          name: "currentLocation",
          label: "Current Location",
          type: "text",
          validated: publicUrlField?.currentLocationRequered === true ? true : false,
          errormsg: "Current Location is required",
          placeholder: "Enter your current location",
          helpText: jobData?.jobLocation ? `Job Location is ${jobData?.jobLocation}` : "",
          dependOn: {
            name: "isPublicUrl",
            value: publicUrlField?.currentLocation === false ? publicUrlField?.currentLocation : true,
            type: "hidden",
          },
        },
        {
          id: "totalExperience",
          name: "totalExperience",
          label: "Total Years of Experience",
          type: "select",
          validated: publicUrlField?.totalExperienceRequered === true ? true : false,
          errormsg: "Total Years of Experience is required",
          placeholder: "Select your total years of experience",
          options: totalExperience,
          dependOn: {
            name: "isPublicUrl",
            value: publicUrlField?.totalExperience === false ? publicUrlField?.totalExperience : true,
            type: "hidden",
          },
        },
        {
          id: "isLocation",
          name: "isLocation",
          label: "Are you willing to change location?",
          type: "select",
          options: [
            { value: "", label: "" },
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" },
          ],
          helpText: jobData?.jobLocation ? `Job Location is ${jobData?.jobLocation}` : "",
          dependOn: {
            name: "isPublicUrl",
            value:
              publicUrlField?.changeLocationRequered === false ? publicUrlField?.changeLocationRequered : true,
            type: "hidden",
          },
        },
      ],
    },
  ];

  return fields;
};

export const candidateDetailsInitialValues = {
  preferWorkMode: "In office",
  fullName: "",
  mobile: "",
  platform: "",
  resume: [],
  email: "",
  totalExperience: "",
  expectedCTC: "",
  noticePeriod: "",
  currentLocation: "",
  sendAssesment: false,
  isPublicUrl: true,
  isLocation: "",
  currentCTC: "",
  skills: "",
  candidateTitle: "",
};

export const candidateDetailsPublicInitialValues = {
  preferWorkMode: "",
  fullName: "",
  mobile: "",
  platform: "",
  resume: [],
  email: "",
  totalExperience: "",
  expectedCTC: "",
  noticePeriod: "",
  currentLocation: "",
  sendAssesment: false,
  isPublicUrl: true,
  isLocation: "",
  currentCTC: "",
  skills: "",
  candidateTitle: "",
};

const filterOperatorChoices = [
  { label: "", value: "" },
  { label: "Greater Than", value: "$gt" },
  { label: "Greater Than and Equals to", value: "$gte" },
  { label: "Equals to", value: "$eq" },
  { label: "Less Than", value: "$lt" },
  { label: "Less Than and Equals to", value: "$lte" },
];
// productCount

const compareFormField = (field, nestedKey, type, label) => {
  const key = nestedKey ? { nestedKey } : {};
  return {
    key: field,
    label: label || field,
    formFields: [
      {
        id: field,
        name: field,
        label: label,
        nested: "object",
        groupSize: 1,
        section: false,
        subfields: [
          {
            id: "operators",
            name: "operators",
            label: "Operators",
            type: "select",
            options: filterOperatorChoices,
          },
          {
            id: type,
            name: type,
            label: "Value",
            type: type,
            min: 0,
          },
        ],
      },
    ],
    filterType: type,
    ...key,
  };
};

const makeNormalField = (field, type = "text", label) => {
  return {
    key: field,
    label: label,
    formFields: [
      {
        id: field,
        name: field,
        label: label,
        type: type,
      },
    ],
  };
};

const noticePeriod = [
  { value: "Currently serving", label: "Currently serving" },
  { value: "15", label: "15" },
  { value: "30", label: "30" },
  { value: "60", label: "60" },
  { value: "90", label: "90" },
  { value: "90+", label: "90+" },
];

const workMode = [
  { value: "In office", label: "Office" },
  { value: "Remote", label: "Remote" },
  { value: "Hybrid", label: "Hybrid" },
  { value: "Any working model is fine by me", label: "Any working model is fine by me" },
];

const Relocatable = [
  { label: "", value: "" },
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const makeSelectField = (field, type = "select", label) => {
  return {
    key: field,
    label: label,
    formFields: [
      {
        id: field,
        name: field,
        label: label,
        type: "select",
        options: field === "preferWorkMode" ? workMode : field === "isLocation" ? Relocatable : noticePeriod,
      },
    ],
  };
};

const makeChoiceField = (field, nestedKey, label, falseLable, trueLable) => {
  const key = nestedKey ? { nestedKey } : {};
  return {
    key: field,
    label: label || field,
    formFields: [
      {
        id: field,
        name: field,
        label: label,
        type: "choiceList",
        choices: [
          { label: trueLable, value: true },
          { label: falseLable, value: false },
        ],
      },
    ],
    initialValue: [],
    filterType: "array",
    dynamicLabel: [
      { label: trueLable, value: true },
      { label: falseLable, value: false },
    ],
    ...key,
  };
};

const assessmentChoiceField = (field, nestedKey, label, falseLable, trueLable) => {
  const key = nestedKey ? { nestedKey } : {};
  return {
    key: field,
    label: label || field,
    formFields: [
      {
        id: field,
        name: field,
        label: label,
        type: "choiceList",
        choices: [
          { label: trueLable, value: true },
          { label: falseLable, value: false },
        ],
      },
    ],
    initialValue: [],
    filterType: "array",
    dynamicLabel: [
      { label: trueLable, value: true },
      { label: falseLable, value: false },
    ],
    ...key,
  };
};

export const getFilterField = () => {
  return [
    compareFormField("totalExperience", "", "number", "Total Experience"),
    compareFormField("totalScore", "", "number", "Assessment Score"),
    assessmentChoiceField("assessmentStatus", "lastStage.isSubmit", "Assessment Status", "Pending", "Completed"),
    assessmentChoiceField("isStar", "", "Star", "No", "Yes"),
    makeSelectField("noticePeriod", "select", "Notice Period"),
    makeChoiceField("reject", "", "Status", "Shortlisted", "Rejected"),
    makeNormalField("platform", "text", "Platform"),
    compareFormField("expectedCTC", "", "number", "Expected CTC"),
    compareFormField("currentCTC", "", "number", "Current CTC"),
    makeNormalField("currentLocation", "text", "Location"),
    makeSelectField("preferWorkMode", "select", "Work Mode"),
    compareFormField("candidateRate", "", "number", "Feedback Rate"),
    makeSelectField("isLocation", "select", "Relocatable"),
    compareFormField("resumeScore", "resumeScore.score", "number", "Resume Score"),
  ];
};

export const candidateFeedbackFormFields = [
  {
    id: "candidateFeedback",
    name: "candidateFeedback",
    label: "Feedback",
    validated: false,
    type: "text",
    placeholder: "Provide feedback on the candidate's performance.",
    multiline: 3,
  },
];

export const candidateFeedbackFormitialValues = {
  candidateFeedback: "",
};

export const candidateRateFormFields = [
  {
    id: "performance",
    name: "performance",
    label: "Please rate the candidate performance.",
    validated: true,
    type: "rangeSlider",
    max: 10,
    min: 0,
    step: 0.5,
  },
];

export const candidateRateFormitialValues = {
  performance: 0,
};

export const getImportCandidateDetailFields = (option) => {
  let fields = [
    {
      id: "fullName",
      name: "fullName",
      label: "Full Name :",
      validated: false,
      type: "select",
      placeholder: "Select Full Name column",
      errormsg: "Full Name is required",
      options: option,
    },
    {
      id: "email",
      name: "email",
      label: "Email Address :",
      validated: true,
      type: "select",
      placeholder: "Select Email Address column",
      errormsg: "Email Address is required",
      options: option,
    },
    {
      id: "mobile",
      name: "mobile",
      label: "Mobile :",
      type: "select",
      validated: false,
      placeholder: "Select Mobile number column",
      options: option,
    },
    {
      id: "preferWorkMode",
      name: "preferWorkMode",
      label: "Prefer Work Mode :",
      type: "select",
      placeholder: "Select Work Mode column",
      options: option,
    },
    {
      id: "currentCTC",
      name: "currentCTC",
      label: "Current CTC :",
      type: "select",
      placeholder: "Select Current CTC column",
      options: option,
    },
    {
      id: "expectedCTC",
      name: "expectedCTC",
      label: "Expected CTC :",
      type: "select",
      placeholder: "Select Expected CTC column",
      options: option,
    },
    {
      id: "noticePeriod",
      name: "noticePeriod",
      label: "Notice Period :",
      type: "select",
      placeholder: "Select notice period column",
      options: option,
    },
    {
      id: "currentLocation",
      name: "currentLocation",
      label: "Current Location :",
      type: "select",
      placeholder: "Select current location column",
      options: option,
    },
    {
      id: "totalExperience",
      name: "totalExperience",
      label: "Total Years of Experience :",
      type: "select",
      placeholder: "Select total years of experience column",
      options: option,
    },
  ];

  return fields;
};
const assessmentSend = (type) => [
  {
    nested: "group",
    section: type,
    groupSize: 1,
    subfields: [
      {
        radioId: "Completed",
        id: "Completed",
        name: "assessment",
        label: "Assessment Completed",
        type: "radio",
        dependOn: {
          name: "sendAssessment",
          value: "NextRound",
          type: "hidden",
        },
      },
      {
        radioId: "CompletedSend",
        id: "CompletedSend",
        name: "assessment",
        label: "Assessment Completed & Send",
        type: "radio",
        dependOn: {
          name: "sendAssessment",
          value: "NextRound",
          type: "hidden",
        },
      },
      {
        radioId: "allAssessment",
        id: "allAssessment",
        name: "assessment",
        label: "All Assessment",
        type: "radio",
        dependOn: {
          name: "sendAssessment",
          value: "NextRound",
          type: "hidden",
        },
      },
    ],
  },
];
const nextassessmentSend = (type) => [
  {
    nested: "group",
    section: type,
    groupSize: 1,
    subfields: [
      {
        radioId: "Completed",
        id: "Completed",
        name: "assessment",
        label: "Assessment Completed",
        type: "radio",
        dependOn: {
          name: "sendAssessment",
          value: "NextSendAssessment",
          type: "hidden",
        },
      },
      {
        radioId: "CompletedSend",
        id: "CompletedSend",
        name: "assessment",
        label: "Assessment Completed & Send",
        type: "radio",
        dependOn: {
          name: "sendAssessment",
          value: "NextSendAssessment",
          type: "hidden",
        },
      },
      {
        radioId: "allAssessment",
        id: "allAssessment",
        name: "assessment",
        label: "All Assessment",
        type: "radio",
        dependOn: {
          name: "sendAssessment",
          value: "NextSendAssessment",
          type: "hidden",
        },
      },
    ],
  },
];
export const bulkSendAssessmentFormFields = (option, NextRound, NextSendAssessment, isLastStage) => {
  let subfields = [
    {
      radioId: "SendAssessmentAll",
      id: "SendAssessmentAll",
      name: "sendAssessment",
      label: "Send Assessment (Not send yet)",
      type: "radio",
    },
    {
      radioId: "NextRound",
      id: "NextRound",
      name: "sendAssessment",
      label: "Move to next round",
      type: "radio",
      dependOn: {
        name: "stage",
        value: isLastStage,
        type: "disabled",
      },
    },
    ...(NextRound ? assessmentSend(NextRound) : []),

    {
      radioId: "NextSendAssessment",
      id: "NextSendAssessment",
      name: "sendAssessment",
      label: "Move to next round and send assessment",
      type: "radio",
      dependOn: {
        name: "stage",
        value: isLastStage,
        type: "disabled",
      },
    },
    ...(NextSendAssessment ? nextassessmentSend(NextSendAssessment) : []),
  ];

  let fields = [
    {
      id: "stage",
      name: "stage",
      label: "Stage",
      validated: true,
      type: "select",
      placeholder: "Select Stage",
      options: option,
      errormsg: "Stage is required",
    },
    {
      nested: "group",
      section: true,
      groupSize: 1,
      subfields: subfields,
    },
  ];

  return fields;
};
export const bulkSendAssessmentInitialValues = {
  stage: "",
  sendAssessment: "",
  assessment: "Completed",
};

export const sendAssessmentFields = [
  {
    id: "sendAssessment",
    name: "sendAssessment",
    label: "How would you like to send the assessment?",
    validated: true,
    min: 1,
    // max: 3,
    type: "choiceList",
    errormsg: "Assessment List is required",
    allowMultiple: true,
    choices: [
      { value: "email", label: "Email" },
      { value: "whatsapp", label: "Whatsapp" },
    ],
  },
];

export const sendAssessmentInitialValues = {
  sendAssessment: "",
};

export const contectPersonFields = [
  {
    id: "contectPersonMassage",
    name: "contectPersonMassage",
    label: "Write a message to the contact person",
    validated: true,
    type: "text",
    placeholder: "Write a message to the contact person",
    errormsg: "Message is required",
    multiline: 5,
  },
];

export const contectPersonInitialValues = {
  contectPersonMassage: "",
};
