import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Badge, BlockStack, Box, Button, Card, Divider, InlineStack, Tabs, Text } from "@shopify/polaris";
import { formatDistanceToNow } from "date-fns";
import { useAuthenticatedFetch } from "@/Api/Axios";
import ProgressCircle from "@/Components/Common/ProgressCircle";
import AssessmentStatus from "../Jobs/AssessmentStatus";
import SendAssessment from "../Jobs/SendAssessment";
import { ResultBlock } from "./ResultBlock";

export const AssessmentResult = (props) => {
  const { candidateData, isPrivate } = props;
  const [assessmentData, setAssessmentData] = useState();
  const [stageCadidateData, setStageCadidateData] = useState(candidateData);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(null);
  const fetch = useAuthenticatedFetch();
  const [assessments, setAssessments] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [selected, setSelected] = useState(0);
  const [isSendingAssessment, setIsSendingAssessment] = useState(false);
  const childRef = useRef();
  const [stages, setStages] = useState([]);
  const [lastStage, setLatStage] = useState();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [isStatus, setIsStatus] = useState(false);
  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      setAssessmentData(
        assessments?.[selectedTabIndex]?.["assessmentData"]
          ? assessments?.[selectedTabIndex]?.["assessmentData"]
          : ""
      );
    },
    [assessments]
  );

  const getAssessmentResult = useCallback(async () => {
    const shareParam = urlParams.get("share");
    let candidates;
    if (isStatus) {
      let candidate = shareParam
        ? await fetch.get(`/candidate/${candidateData._id}?share=${shareParam}`, false)
        : await fetch.get(`/candidate/${candidateData._id}`, false);
      setStageCadidateData(candidate?.data);
      candidates = candidate?.data;
    } else {
      candidates = candidateData;
    }
    let res = await fetch.post(`/assessment/result`, { candidates }, false);
    if (isPrivate) {
      let stagesData = await fetch.get(`/stages/${stageCadidateData.jobId}`, false);
      setStages(stagesData?.data?.rows);
      setLatStage(stagesData?.data?.rows[stagesData?.data?.rows?.length - 1]);
    }
    setAssessments(res?.data?.stages || []);
    const stageId = urlParams.get("id");
    if (stageId) {
      const index = res?.data?.stages.findIndex((item) => item._id === stageId);
      handleTabChange(index);
    }
    setTabs(
      res?.data?.stages?.map((stage, index) => ({
        id: index,
        content: stage.stageDetails.stageTitle,
      }))
    );
  }, [stageCadidateData, assessments, stages, lastStage, isStatus, tabs]);

  const handleMoveToNextStage = useCallback(async (row) => {
    await fetch.post("/movecandidate", {
      row: row,
      jobId: stageCadidateData.jobId,
    });
    await getAssessmentResult();
  }, []);

  const handlePlayButtonClick = (videoIndex) => {
    setSelectedVideoIndex(videoIndex);
  };

  useEffect(() => {
    if (isStatus) {
      getAssessmentResult();
    }
  }, [props.candidateData, isStatus]);

  useEffect(() => {
    if (!isSendingAssessment) {
      getAssessmentResult();
    }
  }, [isSendingAssessment]);

  useEffect(() => {
    setAssessmentData(assessments?.[0]?.["assessmentData"]);
  }, [assessments, isSendingAssessment]);

  const formatTimeAgo = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      return "N/A";
    }
  };

  return (
    <BlockStack gap="300">
      <Text variant="headingXl">View Assessment</Text>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        {assessments?.map(
          (stage, stageIndex) =>
            selected === stageIndex && ( // Here we return the JSX element inside parentheses
              <Card padding="600" key={stageIndex}>
                {/* Add key for each mapped element */}
                <BlockStack gap="200">
                  {assessments.length - 1 === stageIndex && isPrivate && (
                    <Text variant="headingMd">Current Interview Round</Text>
                  )}
                  {!assessmentData && (
                    <BlockStack gap="400">
                      <Text variant="headingMd">Assessment not send.</Text>

                      {isPrivate && (
                        <InlineStack>
                          <SendAssessment
                            candidate={stageCadidateData}
                            setIsSendingAssessment={setIsSendingAssessment}
                            isSendingAssessment={isSendingAssessment}
                            childRef={childRef}
                            stage={stage}
                            setIsStatus={setIsStatus}
                          ></SendAssessment>
                        </InlineStack>
                      )}
                    </BlockStack>
                  )}

                  {stage && assessmentData?.answers?.length <= 0 && (
                    <BlockStack gap="200">
                      <Text variant="headingMd">Test not yet submitted</Text>

                      <InlineStack gap={200}>
                        {isPrivate && <AssessmentStatus row={candidateData} stage={stage}></AssessmentStatus>}
                      </InlineStack>
                    </BlockStack>
                  )}

                  {assessments.length - 1 === stageIndex &&
                    stages.length !== assessments.length &&
                    !assessmentData?.isSubmit &&
                    isPrivate &&
                    assessmentData?.answers?.length == 0 &&
                    lastStage?.stageDetails?.stageTitle !== stage?.stageDetails?.stageTitle && (
                      <BlockStack gap="200">
                        <InlineStack gap={200}>
                          <Button primary onClick={() => handleMoveToNextStage(stageCadidateData)}>
                            Move to next Interview Round
                          </Button>
                          <Button primary onClick={() => handleMoveToNextStage(stageCadidateData)}>
                            Skip this Interview Round
                          </Button>
                        </InlineStack>
                      </BlockStack>
                    )}
                  {assessmentData && assessmentData?.answers?.length > 0 && (
                    <BlockStack gap="200">
                      {assessmentData?.assessmentCompleted && (
                        <InlineStack gap="200">
                          <Text as="p" tone="subdued">
                            Complated At :
                          </Text>
                          <Text as="p" fontWeight="medium">
                            {formatTimeAgo(assessmentData?.assessmentCompleted)},
                          </Text>
                        </InlineStack>
                      )}
                      {assessmentData && assessmentData?.isOngoing && (
                        <>
                          <InlineStack>
                            <Badge tone="warning">On Going</Badge>
                          </InlineStack>
                        </>
                      )}
                      <>
                        {assessmentData?.startedAt && (
                          <InlineStack gap="200">
                            <Text as="p" tone="subdued">
                              Started At :
                            </Text>
                            <Text as="p" fontWeight="medium">
                              {formatTimeAgo(assessmentData?.startedAt)}
                            </Text>
                          </InlineStack>
                        )}
                        <InlineStack gap="600">
                          {assessmentData?.ipAddressData?.country && (
                            <InlineStack gap="200">
                              <Text as="p" tone="subdued">
                                Country :
                              </Text>
                              <Text as="p" fontWeight="medium">
                                {assessmentData?.ipAddressData?.country},
                              </Text>
                            </InlineStack>
                          )}
                          {assessmentData?.ipAddressData?.city && (
                            <InlineStack gap="200">
                              <Text as="p" tone="subdued">
                                City :
                              </Text>
                              <Text as="p" fontWeight="medium">
                                {assessmentData?.ipAddressData?.city},
                              </Text>
                            </InlineStack>
                          )}
                          {assessmentData?.ipAddressData?.query && (
                            <InlineStack gap="200">
                              <Text as="p" tone="subdued">
                                IP Address :
                              </Text>
                              <Text as="p" fontWeight="medium">
                                {assessmentData?.ipAddressData?.query},
                              </Text>
                            </InlineStack>
                          )}
                          {assessmentData?.ipAddressData?.zip && (
                            <InlineStack gap="200">
                              <Text as="p" tone="subdued">
                                Zip Code :
                              </Text>
                              <Text as="p" fontWeight="medium">
                                {assessmentData?.ipAddressData?.zip},
                              </Text>
                            </InlineStack>
                          )}
                          {assessmentData?.ipAddressData?.device && (
                            <InlineStack gap="200">
                              <Text as="p" tone="subdued">
                                Device :
                              </Text>
                              <Text as="p" fontWeight="medium">
                                {assessmentData?.ipAddressData?.device},
                              </Text>
                            </InlineStack>
                          )}
                          {assessmentData?.ipAddressData?.osSystem && (
                            <InlineStack gap="200">
                              <Text as="p" tone="subdued">
                                OS :
                              </Text>
                              <Text as="p" fontWeight="medium">
                                {assessmentData?.ipAddressData?.osSystem},
                              </Text>
                            </InlineStack>
                          )}
                          {assessmentData?.ipAddressData?.browser && (
                            <InlineStack gap="200">
                              <Text as="p" tone="subdued">
                                Browser :
                              </Text>
                              <Text as="p" fontWeight="medium">
                                {assessmentData?.ipAddressData?.browser}
                              </Text>
                            </InlineStack>
                          )}
                        </InlineStack>
                      </>

                      {assessmentData && assessmentData?.isProcessing && (
                        <InlineStack>
                          <Badge tone="warning">Processing</Badge>
                        </InlineStack>
                      )}
                      {assessments.length - 1 === stageIndex && isPrivate && (
                        <BlockStack gap="200">
                          {stages.length !== assessments.length &&
                            lastStage?.stageDetails?.stageTitle !== stage?.stageDetails?.stageTitle && (
                              <InlineStack gap={200}>
                                <Button primary onClick={() => handleMoveToNextStage(stageCadidateData)}>
                                  Move to next Interview Round
                                </Button>
                                <Button primary onClick={() => handleMoveToNextStage(stageCadidateData)}>
                                  Skip this Interview Round
                                </Button>
                              </InlineStack>
                            )}
                        </BlockStack>
                      )}

                      {assessmentData && assessmentData?.isSubmit && (
                        <>
                          <InlineStack gap="200" blockAlign="center" align="center">
                            <BlockStack inlineAlign={!assessmentData?.overallScore ? "center" : "start"}>
                              <Text alignment="center" variant="headingLg">
                                Score Analysis
                              </Text>
                              {!assessmentData?.overallScore && assessmentData?.overallScore !== 0 && (
                                <Text variant="bodySm">No score data found.</Text>
                              )}
                              <Text alignment="center" variant="headingMd">
                                Tab Changes Count :{" "}
                                {assessmentData?.tabChangeCount ? assessmentData?.tabChangeCount : 0}
                              </Text>
                            </BlockStack>
                            {assessmentData?.overallScore >= 0 && (
                              <ProgressCircle
                                score={assessmentData?.overallScore?.toFixed(2)}
                                fontSize="16px"
                                width="80px"
                                border="10px"
                              />
                            )}
                          </InlineStack>
                        </>
                      )}

                      <Divider />
                      {assessmentData?.questionsList?.map((question, index) => {
                        if (question.aiGenerated) {
                          question["questionType"] = "text";
                          question["answerType"] = "video";
                        }
                        question["pair"] =
                          question?.questionType && question?.answerType
                            ? `${question.questionType}-${question.answerType}`
                            : "";
                        return (
                          <div key={index}>
                            {question?.answer && (
                              <div className={`question-answer-block ${question.pair}`} key={index}>
                                <Box
                                  paddingBlockStart={400}
                                  paddingBlockEnd={400}
                                  borderColor="border"
                                  borderBlockStartWidth={index == 0 ? 0 : "050"}
                                >
                                  <InlineStack key={index} gap="600" align="start" wrap={false}>
                                    <ResultBlock item={question} index={index} type="question" />
                                    <ResultBlock item={question} index={index} type="answer" />
                                  </InlineStack>
                                </Box>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </BlockStack>
                  )}
                </BlockStack>
              </Card>
            )
        )}
      </Tabs>
    </BlockStack>
  );
};
