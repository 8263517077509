export const sourcingFormFields = [
  {
    nested: "group",
    groupSize: 5,
    section: false,
    subfields: [
      {
        id: "workExperience",
        name: "workExperience",
        label: "Work Experience",
        validated: false,
        type: "number",
        errormsg: "Work Experience is required",
      },

      {
        id: "location",
        name: "location",
        label: "Location",
        validated: false,
        type: "text",
        errormsg: "Country is required",
      },
      {
        id: "keySkills",
        name: "keySkills",
        label: "Skills",
        validated: false,
        type: "text",
        errormsg: "Country is required",
      },
    ],
  },
];

export const sourcingInitialValues = {
  workExperience: "",
  location: "",
  keySkills: "",
};
